import React from "react"
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from "../components/seo"
import Hero from '../components/hero'
import Listing from '../components/listing'
import Banner from "../components/banner"

import { prettyCategory } from '../utils/helpers'

/** @jsx jsx */
import { jsx } from 'theme-ui'

const singleTag = (props) => {
    const listings = props.data.allPrismicEntreprise.edges.map( ({node: listing}) => listing )
    // const tag = props.pageContext.tag
    const tagName = props.pageContext.tagName
    const category = props.pageContext.category
    const htmlSeoText = props.data.prismicTag.data.seo_text.html
    var resultsNumber = listings.filter( listing => listing !== null ).length

    // console.log("LISTINGS", listings)
    // console.log(props)

    return (
        <Layout>
            <SEO title={""} description={""} />

            <Hero 
                tag={tagName}
                // resultsNumber={resultsNumber}
            />

            <div>            
                {listings.map( (listing, index) => {
                    let row = (
                        <React.Fragment key={listing.id}>
                            <Listing 
                                key={listing.id}
                                uid={listing.uid}
                                data={listing.data}
                                prettyCategory={prettyCategory(category)}
                            />
                            {index > 3 && (index === 4 || index === 14 || index === 34) && <Banner position={index + 2} />}
                        </React.Fragment>
                    )
                    return row
                })}
                {resultsNumber <= 7 && <Banner />}
            </div>

            {htmlSeoText && <div dangerouslySetInnerHTML={{__html: htmlSeoText}}/>}
        </Layout>
    )
}

export default singleTag

export const singleCantonQuery = graphql`
    query singleTagListings($category: String!, $tags: [String!], $slug: String!) {
        allPrismicEntreprise(
            filter: {   
                tags: {in: $tags},
                data: {
                    categories: {elemMatch: {category: {uid: {eq: $category}}}}, 
                    draft: {ne: true}
                }
            },
            sort: {fields: data___rank, order: ASC}
            ) {
            edges {
                node {
                    id
                    uid
                    ...ListingFragment
                }
            }
        }
        prismicTag(uid: {eq: $slug}) {
            data {
                seo_text {
                  html
                }
            }
        }
    }
`